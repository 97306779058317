import React from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { Contact } from '../components/pages/contact/contact-container'

const ContactPage = () => (
  <Layout>

    <SEO
      title="Contact"
      keywords={['contact', 'contact me', 'cooking', 'traveling', 'travel', 'faq', 'question', 'questions']}
    />

    <Contact />

  </Layout>
)

export default ContactPage;