import styled from 'styled-components'

import { media } from '../../../constants/media'
import { MainTitle, BodyText } from '../../common/text-styled'
import { Button } from '../../common/inputs/button-styled'

export const Container = styled.div`
  margin: 0 40px;
  @media ${media.medium.query} {
    margin: 0 20px;
  }
`;

export const Title = styled(MainTitle)`
  margin-bottom: 40px;
  text-align: center;
  @media ${media.medium.query} {
    margin-bottom: 40px;
    text-align: left;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 75px) 300px 25px;
  grid-template-areas:
    "firstName"
    "lastName"
    "email"
    "reasonForContact"
    "message"
    "submit";
  grid-row-gap: 40px;
  @media ${media.medium.query} {
    margin: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 75px) 300px 75px;
    grid-template-areas:
      "firstName lastName"
      "email email"
      "reasonForContact reasonForContact"
      "message message"
      "submit submit";
    grid-row-gap: 50px;
    grid-column-gap: 50px;
  }
`;

export const FirstNameArea = styled.div`
  grid-area: firstName;
`;

export const LastNameArea = styled.div`
  grid-area: lastName;
`;

export const EmailArea = styled.div`
  grid-area: email;
`;

export const ReasonForContactArea = styled.div`
  grid-area: reasonForContact;
`;

export const MessageArea = styled.div`
  grid-area: message;
`;

export const SubmitArea = styled.div`
  grid-area: submit;
`;

export const Label = styled.h4`
  text-align: left;
  margin-bottom: 8px;

  color: ${props => props.theme.colors.salmon};
  font-family: ${props=> props.theme.fonts.poppins};
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.6rem;
  letter-spacing: 0.012rem;
`;

export const ErrorMessage = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 2.5rem;
  color: ${props => props.theme.concepts.errorColor};
`;

export const SuccessMessage = styled(BodyText)`
  margin-bottom: 40px;
  text-align: center;
  @media ${media.medium.query} {
    margin-bottom: 50px;
    text-align: left;
  }
`;

export const HomeButton = styled(Button)`
`;
