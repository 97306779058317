export const makeContactRequest = ({
  apiUrl,
  name,
  email,
  reason,
  message
}) => {
  const CONTACT_ENDPOINT = `${apiUrl}/contact`;

  return fetch(CONTACT_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      reason,
      message
    }),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
}