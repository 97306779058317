import styled from 'styled-components'

import { media } from '../../../constants/media'

export const TextAreaInput = styled.textarea`
  font-family: ${props => props.theme.fonts.poppins};
  color: ${props => props.theme.colors.nori};
  background: none;
  border: none;
  outline: none;
  border: 4px dotted ${props => props.theme.colors.nori};
  padding: 20px 16px;
  width: 100%;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.7rem;
  @media ${media.medium.query} {
    font-size: 2.0rem;
    line-height: 2.5rem;
  }
`;