import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import * as S from './form-fields-styled'

const FirstNameField = ({ value, onChange, isDisabled }) => (
  <FadeIn>

    <S.Label>First Name</S.Label>

    <S.Input
      placeholder="John"
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    />

  </FadeIn>
)

FirstNameField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export { FirstNameField }