import styled from 'styled-components'

import { TextInput } from '../../../common/inputs/text-input-styled'
import { SelectInput } from '../../../common/inputs/select-input-styled'
import { TextAreaInput } from '../../../common/inputs/text-area-styled'
import { Button } from '../../../common/inputs/button-styled'
import { LoadingSpinner } from '../../../common/loading-spinner/loading-spinner-component'

export const Label = styled.h4`
  text-align: left;
  margin-bottom: 8px;

  color: ${props => props.theme.colors.salmon};
  font-family: ${props=> props.theme.fonts.poppins};
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.6rem;
  letter-spacing: 0.012rem;
`;

export const Input = styled(TextInput)`
`;

export const Select = styled(SelectInput)`
`;

export const Textarea = styled(TextAreaInput)`
`;

export const SendButton = styled(Button)`
`;

export const LoadingIcon = styled(LoadingSpinner)`
`;