import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import * as S from './form-fields-styled'

const SubmitField = ({ onClick, isSubmitting }) => (
  <FadeIn>

    {isSubmitting ? (

      <S.LoadingIcon />

    ) : (

      <S.SendButton onClick={onClick}>
        Send Message
      </S.SendButton>

    )}

  </FadeIn>
)

SubmitField.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

export { SubmitField }