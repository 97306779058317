import { useState } from 'react'

import { isValidEmail } from '../../../helpers/email/email'
import { makeContactRequest } from './contact-networking'

const reasonForContactOptions = [
  { text: 'I have a question about a recipe', value: 'recipe' },
  { text: 'Can I use your photo and/or recipe?', value: 'rights' },
  { text: 'Sponsored Content/Advertising', value: 'advertising' },
  { text: 'Other', value: 'other' },
]

export const useContactForm = ({ apiUrl }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [reasonForContact, setReasonForContact] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmitForm = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const errors = [];

    // Validate
    if (!firstName && !lastName) {
      errors.push('Name is required');
    }
    if (!isValidEmail(emailAddress)) {
      errors.push('Invalid email');
    }
    if (!reasonForContactOptions.map(item => item.value).includes(reasonForContact)) {
      errors.push('Missing reason for contact')
    }
    if (!message) {
      errors.push('Message cannot be empty')
    }

    // TODO: Set message character limit (on front-end AND back-end)

    if (errors.length > 0) {
      setErrorMessages(errors);
      setIsLoading(false);
    } else {
      makeContactRequest({
        apiUrl,
        name: `${firstName} ${lastName}`,
        email: emailAddress,
        reason: reasonForContact,
        message: message
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            throw new Error(response.error);
          } else {
            setIsLoading(false);
            setShowSuccessMessage(true);
          }
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
          setErrorMessages([err.message]);
        })
    }
  }

  return {
    firstName,
    setFirstName,

    lastName,
    setLastName,

    emailAddress,
    setEmailAddress,

    reasonForContact,
    setReasonForContact,
    reasonForContactOptions,

    message,
    setMessage,

    errorMessages,

    isLoading,

    showSuccessMessage,

    handleSubmitForm,
  }
}