import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import { useContactForm } from './use-contact-form-hook'
import { FirstNameField } from './form-fields/first-name-field'
import { LastNameField } from './form-fields/last-name-field'
import { EmailField } from './form-fields/email-field'
import { ReasonForContactField } from './form-fields/reason-for-contact-field'
import { MessageField } from './form-fields/message-field'
import { SubmitField } from './form-fields/submit-field'
import { ROUTES } from '../../../constants/routes'
import * as S from './contact-styled'

const ContactComponent = ({ apiUrl }) => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    emailAddress,
    setEmailAddress,
    reasonForContact,
    setReasonForContact,
    message,
    setMessage,
    errorMessages,
    isLoading,
    showSuccessMessage,
    handleSubmitForm,
    reasonForContactOptions,
  } = useContactForm({ apiUrl });

  return (
    <S.Container>

      <FadeIn>
        <S.Title>Contact</S.Title>
      </FadeIn>

      {showSuccessMessage ? (

        <FadeIn>

          <S.SuccessMessage>
            You're message has been sent successfully!
          </S.SuccessMessage>

          <S.HomeButton onClick={() => window.location.href = ROUTES.home.path}>
            Back to Home
          </S.HomeButton>

        </FadeIn>

      ) : (
        <form>

          <S.Grid>

            <S.FirstNameArea>
              <FirstNameField
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                isDisabled={isLoading}
              />
            </S.FirstNameArea>

            <S.LastNameArea>
              <LastNameField
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                isDisabled={isLoading}
              />
            </S.LastNameArea>

            <S.EmailArea>
              <EmailField
                value={emailAddress}
                onChange={e => setEmailAddress(e.target.value)}
                isDisabled={isLoading}
              />
            </S.EmailArea>

            <S.ReasonForContactArea>
              <ReasonForContactField
                value={reasonForContact}
                onChange={e => setReasonForContact(e.target.value)}
                isDisabled={isLoading}
                options={reasonForContactOptions}
              />
            </S.ReasonForContactArea>

            <S.MessageArea>
              <MessageField
                value={message}
                onChange={e => setMessage(e.target.value)}
                isDisabled={isLoading}
              />
            </S.MessageArea>

            <S.SubmitArea>

              {errorMessages.length > 0 && (

                <S.ErrorMessage>
                  {errorMessages.join('. ')}
                </S.ErrorMessage>

              )}

              <SubmitField
                onClick={handleSubmitForm}
                isSubmitting={isLoading}
              />

            </S.SubmitArea>

          </S.Grid>

        </form>
      )}

    </S.Container>
  )
}

ContactComponent.propTypes = {
  apiUrl: PropTypes.string.isRequired,
}

export { ContactComponent }