import React from 'react'
import PropTypes from 'prop-types'
import FadeIn from 'react-fade-in'

import * as S from './form-fields-styled'

const ReasonForContactField = ({ value, onChange, isDisabled, options }) => (
  <FadeIn>

    <S.Label>Reason for Contact</S.Label>

    <S.Select
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    >

      <option value="">Select One</option>

      {options.map((opt, i) => (

        <option key={(i+1) * 17} value={opt.value}>
          {opt.text}
        </option>

      ))}

    </S.Select>

  </FadeIn>
)

ReasonForContactField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
}

export { ReasonForContactField }