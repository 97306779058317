import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { ContactComponent } from './contact-component'

export const Contact = (props) => (
  <StaticQuery
    query={contactQuery}
    render={data =>
      <ContactComponent
        apiUrl={data.site.siteMetadata.awsApiGatewayUrl}
        {...props} 
      />
    }
  />
)

const contactQuery = graphql`
  query contactQuery {
    site {
      siteMetadata {
        awsApiGatewayUrl
      }
    }
  }
`;